import type { Weekly } from "@msuite/katana";

/** Types */
export type PlanDroppableType =
	| "weekly-content"
	| "colon-construction-sites"
	| "colon-employees"
	| "colon-header";
export type PlanDraggableType =
	| "vehicle"
	| "employee"
	| "construction-site"
	| "ticket";
export type DataDroppablePayload = {
	type: PlanDroppableType;
	payload: WeeklyDroppablePayload;
};
export type DataDraggablePayload =
	| {
			type: "ticket";
			payload: TicketDraggablePayload;
			callbackEventOnDrop?: boolean;
	  }
	| {
			type: "vehicle";
			payload: VehicleDraggablePayload;
			callbackEventOnDrop?: boolean;
	  }
	| {
			type: "employee";
			payload: EmployeeDraggablePayload;
			callbackEventOnDrop?: boolean;
	  };
export type WeeklyDroppablePayload = {
	weekly: (Weekly & { id?: string }) | undefined;
	weeklyReference: string;
};
export type TicketDraggablePayload = {
	ticketId: string;
	constructionSiteId: string | undefined;
	ticketIdArray?: string[];
};
export type VehicleDraggablePayload = {
	vehicleId: string;
	licensePlate: string;
};
export type EmployeeDraggablePayload = {
	employeeId: string;
	origin?: string;
};

/** HANDLERS */

/** HELPERS */

/**
 * This function returns the element with the given htmlId.
 * @param htmlId
 * @returns
 */
export function getElementByHTMLId(htmlId: string) {
	try {
		const element = document.getElementById(htmlId);
		if (!element) throw new Error("Element not found");
		return element;
	} catch (error) {
		console.error(error);
		return undefined;
	}
}

/**
 * This function returns the payload of the element with the given htmlId.
 * @param htmlId
 * @returns
 */
export function getPayloadFromHTMLId(htmlId: string) {
	try {
		const element = getElementByHTMLId(htmlId);
		const payload = element?.getAttribute("data-payload");
		if (!payload) throw new Error("Payload not found");
		const payloadJson = JSON.parse(payload);
		return payloadJson as DataDraggablePayload;
	} catch (error) {
		console.error(error);
		return undefined;
	}
}

/**
 * This function is used to stringify the payload.
 * @param payload
 * @returns
 */
export function createDataPayload<
	T extends DataDroppablePayload | DataDraggablePayload,
>(type: T["type"], _payload: T["payload"], callbackEventOnDrop?: boolean) {
	const payload = {
		type: type,
		payload: _payload,
		callbackEventOnDrop,
	} as T;
	return JSON.stringify(payload);
}
