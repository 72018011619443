import { NavigationWrapper } from "@/components/navigation-wrapper";
import { AppContext } from "@/context/app";
import Home from "@/sites/home";
import {
	ComponentLoader,
	ContentLayout,
	FourOhFour,
	PrivateRoute,
	useAuthContext,
	useTitle,
} from "@msuite/picasso";
import { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const Plan = lazy(() => import("@/sites/plan"));
const Employees = lazy(() => import("@/sites/personal/employees"));
const Employee = lazy(() => import("@/sites/personal/employee"));
const Educations = lazy(() => import("@/sites/personal/educations"));
const Subcontractors = lazy(() => import("@/sites/personal/subcontractors"));
const Absences = lazy(() => import("@/sites/personal/absences"));
const Subcontractor = lazy(() => import("@/sites/personal/subcontractor"));
const MySpaceSiteMeasurements = lazy(
	() => import("@/sites/my-space/site-measurements"),
);
const MySpaceSiteMeasurementsSingle = lazy(
	() => import("@/sites/my-space/site-measurements-single"),
);
const ConstructionSites = lazy(
	() => import("@/sites/construction-sites/construction-sites"),
);
const ConstructionSite = lazy(
	() => import("@/sites/construction-sites/construction-site"),
);
const Offers = lazy(() => import("@/sites/my-space/offers"));
const Deputies = lazy(() => import("@/sites/my-space/deputies"));
const CallNotes = lazy(() => import("@/sites/my-space/call-notes"));
const Orders = lazy(() => import("@/sites/my-space/orders"));
const AccountingSiteMeasurementsManagers = lazy(
	() => import("@/sites/accounting/site-measurements-managers"),
);
const AccountingSiteMeasurementsManager = lazy(
	() => import("@/sites/accounting/site-measurements-manager"),
);
const AccountingSiteMeasurementsManagerConstructionSite = lazy(
	() =>
		import("@/sites/accounting/site-measurements-manager-construction-site"),
);
const AccountingInvoices = lazy(() => import("@/sites/accounting/invoices"));
const YearlyStatements = lazy(
	() => import("@/sites/accounting/yearly-statements"),
);
const Rent = lazy(() => import("@/sites/accounting/rent"));
const Cms = lazy(() => import("@/sites/cms"));
const CallNoteMarkAnswer = lazy(
	() => import("@/sites/misc/call-note-mark-answer"),
);
const Contacts = lazy(() => import("@/sites/contacts"));
const VirticCredentials = lazy(
	() => import("@/sites/personal/virtic-credentials"),
);
const Maps = lazy(() => import("@/sites/maps"));

export const App = () => {
	/** Context */
	const { validation, workspaceIds, setWorkspaceId } = useAuthContext();

	/** State */
	const [activeWorkspace, setActiveWorkspace] = useState<string | null>(null);

	/** Effects */
	useEffect(() => {
		function handleSetActiveWorkspace() {
			if (activeWorkspace) return;
			setActiveWorkspace(workspaceIds[0]);
		}
		handleSetActiveWorkspace();
	}, [activeWorkspace, workspaceIds]);

	useEffect(() => {
		if (activeWorkspace) setWorkspaceId(activeWorkspace);
	}, [activeWorkspace, setWorkspaceId]);

	/** Hooks */
	useTitle("Loading");

	/** Render */
	return (
		<AppContext.Provider value={{ activeWorkspace, setActiveWorkspace }}>
			<BrowserRouter>
				<ContentLayout>
					<NavigationWrapper>
						<Suspense fallback={<ComponentLoader />}>
							<Routes>
								<Route
									path="/"
									element={
										<PrivateRoute
											additionalAuthCondition={!validation?.isSubcontractor}
										/>
									}
								>
									<Route
										path="*"
										element={<FourOhFour />}
									/>
									<Route
										path="/"
										element={<Navigate to="/home" />}
									/>
									<Route
										path="/home"
										element={<Home />}
									/>
									<Route
										path="/plan"
										element={<Plan />}
									/>
									<Route
										path="/construction-sites"
										element={<ConstructionSites />}
									/>
									<Route
										path="/maps"
										element={<Maps />}
									/>
									<Route
										path="/construction-sites/:constructionSiteId"
										element={<ConstructionSite />}
									/>
									<Route
										path="/personal/employees"
										element={<Employees />}
									/>
									<Route
										path="/personal/employees/:employeeId"
										element={<Employee />}
									/>
									<Route
										path="/personal/educations"
										element={<Educations />}
									/>
									<Route
										path="/personal/subcontractors"
										element={<Subcontractors />}
									/>
									<Route
										path="/personal/absences"
										element={<Absences />}
									/>
									<Route
										path="/personal/subcontractors/:subcontractorId"
										element={<Subcontractor />}
									/>
									<Route
										path="/personal/virtic-credentials"
										element={<VirticCredentials />}
									/>
									<Route
										path="/my-space/site-measurements"
										element={<MySpaceSiteMeasurements />}
									/>
									<Route
										path="/my-space/site-measurements/:constructionSiteId"
										element={<MySpaceSiteMeasurementsSingle />}
									/>
									<Route
										path="/my-space/offers"
										element={<Offers />}
									/>
									<Route
										path="/my-space/deputies"
										element={<Deputies />}
									/>
									<Route
										path="/my-space/call-notes"
										element={<CallNotes />}
									/>
									<Route
										path="/my-space/orders"
										element={<Orders />}
									/>
									<Route
										path="/accounting/site-measurements"
										element={<AccountingSiteMeasurementsManagers />}
									/>
									<Route
										path="/accounting/site-measurements/:employeeId"
										element={<AccountingSiteMeasurementsManager />}
									/>
									<Route
										path="/accounting/site-measurements/:employeeId/construction-site/:constructionSiteId"
										element={
											<AccountingSiteMeasurementsManagerConstructionSite />
										}
									/>
									<Route
										path="/accounting/invoices"
										element={<AccountingInvoices />}
									/>
									<Route
										path="/accounting/yearly-statements"
										element={<YearlyStatements />}
									/>
									<Route
										path="/accounting/rent"
										element={<Rent />}
									/>
									<Route
										path="/contacts"
										element={<Contacts />}
									/>
									<Route
										path="/cms"
										element={<Cms />}
									/>
									<Route
										path="/misc/call-note-mark-answer/:callNoteId"
										element={<CallNoteMarkAnswer />}
									/>
								</Route>
							</Routes>
						</Suspense>
					</NavigationWrapper>
				</ContentLayout>
			</BrowserRouter>
		</AppContext.Provider>
	);
};
